import React from "react";
import HomeSubSection from "../WebSite/HomeSubSection/HomeSubSection";
import AdvertisementCBN from "../gbrEngage/Advertisement/AdvertisementCBN";
import { Row, Col } from "react-bootstrap";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
 
export default function AdvertisementService() {
 
 
  return (
    <>
<HeaderComponent/>
<UpdatesBanner
        className="Advertisement-bg"
        bannerText={[
          "Advertisement"
        ]}
      />
    <div className="subSectionWrapper  pb-0">
             <h3 class="container-title text-center">Advertisements</h3>
 
 
<HomeSubSection
  className1="aboutBgGrey"
  className="rowReverseAbout--mod borderedCards borderedCards--mod"
  className3="image19"
  descriptionTitle="
  Machinery Providers"
  description="
  GBR is your trusted source for a diverse range of agricultural machinery. Whether you're a farmer or an farmer franchise, we supply the equipment you need to optimize your operations. Count on us to provide reliable machinery that supports your farming goals and drives efficiency.
  "
  href5="http://abn.gbrapp.com/auth/undefined/signup"
  buttoncont2="Register"
  href4="http://abn.gbrapp.com/"
  buttoncont1="Sign In"
  userType="Farmers-Machinery-Provider"
/>
<AdvertisementCBN />
    </div>
    <FooterComponent/>
    </>
 
  );
}
 
