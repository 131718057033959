
import React from "react";
import HoneyAsset from "../../images/1 (1).png";
import ProcessingSupport from "../../images/bee-nests-flower-garden.jpg"
import OnBoardSupport from "../../images/young-agronomist-showing-some-information-farmer-smartphone-greenhouse-min.jpg"
import CBN from "../../images/gbrCBN/CBNBanner.webp";

import { Row, Col } from "react-bootstrap";

export default function AgroDealerServices() {
  const services = [
    {
      title: "ARN",
      image: CBN,
      href: "/ARNNetwork",
    },
   
    {
        title: "Agrocare Marketplace",
        image: HoneyAsset,
        href: "/agrocare-marketplace",
      },

      // {
      //   title: "Social onMarketplace",
      //   image: FieldSupport,
      //   href: "/socialonMarketplace",
      // },

      {
        title: "Social iaaMarketplace",
        image: ProcessingSupport,
        href: "/iaaMarketplace",
      },

      {
        title: "AR onboarding service",
        image: OnBoardSupport,
        href: "/AR-onbarding",
      },

      
  ];

  return (
    <div className="container for-farmer-section2 px-0 subSectionWrapper pb-0">
      <h2 className="text-center title mb-4 mb-lg-5 container-title">
       Our Services
      </h2>
      <Row className="justify-content-center">
        {services.map((e) => {
          return (
            <Col md={5}>
              <a href={e.href}>
              <div className="image-container mb-4">

                <img className="" src={e.image} alt="Investment asset" />
                </div>
                <h3 className="text-center container-heading1 mt-0 mb-md-5 mb-4 text-decoration-none">
                  {e.title}
                </h3>
              </a>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
